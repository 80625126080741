import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row align-items-center" }
const _hoisted_2 = { class: "col-7" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = { class: "col-5" }
const _hoisted_5 = { class: "mt-3" }
const _hoisted_6 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_7 = { class: "countries-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_CountrySwitch = _resolveComponent("CountrySwitch")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.locationType) + " Countries", 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_AppButton, {
            type: "primary-transparent",
            size: "md",
            class: "ms-auto add-country-button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showLocationsSelection('country')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" + Add Country ")
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.localSelectedCounties.length < 2)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, " Please, add at least 2 countries to this " + _toDisplayString(_ctx.locationType) + ". ", 1))
          : _createCommentVNode("", true),
        _createElementVNode("ul", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localSelectedCounties, ({ id, name, icon }) => {
            return (_openBlock(), _createElementBlock("li", {
              key: id,
              class: "countries-list-item"
            }, [
              _createVNode(_component_CountrySwitch, {
                id: id,
                name: name,
                checked: true,
                icon: icon,
                onSwitch: _ctx.switchLocalCountry
              }, null, 8, ["id", "name", "icon", "onSwitch"])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}