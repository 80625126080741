import {
  ILocationCountry,
  ILocationsStateLists,
  TLocationKeys
} from "@/store/locations";
import { IApiResponse } from "@/api/interfaces/IApi";
import { ISubregion } from "@/api/services/packages/esim/location-packages/types";
import {
  TPackageLocation,
  TPackageLocationSingleWithoutCountry
} from "@/hooks/esim/location-packages/types/locationPackage.types";
import { switchedLocation } from "@/components/Locations/interfaces";

export enum locationStatuses {
  active = "active",
  disabled = "disabled",
  all = "all"
}

export type TLocationStatus = typeof locationStatuses[keyof typeof locationStatuses];

export interface IFetchLocationsDto {
  page?: number;
  perPage?: number;
  extended: boolean;
  status?: keyof locationStatuses;
  name?: string;
}

export interface IFetchLocationsResponse<T extends TLocationKeys>
  extends IApiResponse {
  data: ILocationsStateLists[T];
  total: number;
}

export interface IDeleteLocationDto<T extends TPackageLocation> {
  id: number;
  locationType: T;
}

export interface IFetchNestedLocationsData {
  selected: ILocationCountry[] | ISubregion[];
}

export interface IFetchNestedLocationsResponse extends IApiResponse {
  data: IFetchNestedLocationsData;
}

// Selected locations

export enum selectedLocationListTypes {
  country = "country",
  subregion = "subregion"
}

export enum selectedLocationsTypes {
  region = "region",
  subregion = "subregion"
}

export type selectedLocationListType = keyof typeof selectedLocationListTypes;
export type selectedLocationType = keyof typeof selectedLocationsTypes;

export interface IFetchSelectedLocationsListType {
  country: ILocationCountry[];
  subregion: ISubregion[];
}

export interface IFetchNestedLocationsDto<
  T extends TPackageLocationSingleWithoutCountry
> {
  locationId: number;
  locationType: T;
  listType: "country" | "subregion";
}

export interface IUpdateSelectedLocationsDto {
  locationChildType: "countries" | "subregions";
  locationParentType: "region" | "subregion";
  changeList: switchedLocation[];
}

export interface ICreateLocationDto {
  active: boolean;
  name: string;
  isoCode?: string;
  popular?: boolean;
  changedLocations?: IUpdateSelectedLocationsDto;
  images: {
    icon?: string;
    smallImage?: string;
    bigImage?: string;
  };
}

export enum LocationImageTypes {
  icon = "icon",
  smallImage = "smallImage",
  bigImage = "bigImage"
}

export enum TranslateLocationImageTypes {
  icon = "icon",
  image1 = "smallImage",
  image2 = "bigImage"
}

export type TLocationImageType = keyof typeof LocationImageTypes;

export interface IUploadLocationImageDto {
  type: TLocationImageType;
  image: File;
}
