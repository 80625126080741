import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "d-flex justify-content-end page-heading-toolbar" }
const _hoisted_3 = { class: "page-heading-toolbar--col" }
const _hoisted_4 = { class: "page-heading-toolbar--col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateLocationModal = _resolveComponent("CreateLocationModal")!
  const _component_DeleteLocationConfirm = _resolveComponent("DeleteLocationConfirm")!
  const _component_ShadowControl = _resolveComponent("ShadowControl")!
  const _component_SelectDropdown = _resolveComponent("SelectDropdown")!
  const _component_PageMainHeading = _resolveComponent("PageMainHeading")!
  const _component_AppToolbarTable = _resolveComponent("AppToolbarTable")!
  const _component_AppTableController = _resolveComponent("AppTableController")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CreateLocationModal, {
      key: "1",
      "window-loading": _ctx.modalLoading,
      "action-loading": _ctx.modalActionLoading,
      opened: _ctx.updateModalOpened,
      "location-type": _ctx.parentSelectedTab,
      "single-location-type": _ctx.locationTypeSingle,
      location: _ctx.location,
      "selected-locations-lists": _ctx.localSelectedList,
      "base-locations": { countries: _ctx.countries, subregions: _ctx.subregions },
      onHide: _ctx.endUpdating,
      onSetModalLoading: _ctx.setModalLoading,
      onSave: _ctx.saveUpadtes,
      onToggleSelectedLocations: _ctx.toggleSelectedLocation
    }, null, 8, ["window-loading", "action-loading", "opened", "location-type", "single-location-type", "location", "selected-locations-lists", "base-locations", "onHide", "onSetModalLoading", "onSave", "onToggleSelectedLocations"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_DeleteLocationConfirm, {
        opened: _ctx.deleteModalOpened,
        "location-icon": _ctx.deleteLocation.icon,
        "location-name": _ctx.deleteLocation.name,
        loading: _ctx.modalActionLoading,
        onHide: _ctx.deleteDiscard,
        onCancel: _ctx.deleteDiscard,
        onSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteConfirm(_ctx.deleteLocation.id)))
      }, null, 8, ["opened", "location-icon", "location-name", "loading", "onHide", "onCancel"]),
      _createVNode(_component_PageMainHeading, { title: "Locations" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ShadowControl, {
                id: "locations-search",
                class: "search-control",
                icon: "search",
                placeholder: "Search...",
                name: "locations-search",
                "input-timeout": true,
                onChange: _ctx.searchHandler
              }, null, 8, ["onChange"])
            ]),
            _createVNode(_Transition, { name: "fade" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_SelectDropdown, {
                    "on-select": _ctx.onFilterSelect,
                    list: _ctx.filter
                  }, null, 8, ["on-select", "list"])
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_AppTableController, {
      "show-table": true,
      "search-query": _ctx.searchQuery,
      "empty-button-text": "Add new",
      "empty-key": "Locations",
      "use-loader": false,
      "empty-icon": "locations"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_AppToolbarTable, {
          "pagination-capture-key": _ctx.pageTitle,
          "table-title": _ctx.pageTitle,
          "table-head-table": _ctx.tableHead,
          "table-body": _ctx.rows || [],
          "total-rows": _ctx.totalLocations,
          "table-head-tabs": _ctx.tabs.tabs,
          "show-head-toolbar": false,
          "parent-selected-tab": _ctx.tabs.selectedTab.value,
          "space-between": false,
          "current-page": _ctx.viewConfiguration.page,
          "per-page": _ctx.viewConfiguration.perPage,
          onChangeTab: _ctx.changeTabHandler,
          onChangeViewConfiguration: _ctx.changeViewConfiguration,
          onSort: _ctx.changeSorting,
          onEditItem: _cache[1] || (_cache[1] = id => _ctx.startEditing(id)),
          onOpenForm: _ctx.startAddLocation,
          onRemoveItem: _ctx.startLocationDelete
        }, null, 8, ["pagination-capture-key", "table-title", "table-head-table", "table-body", "total-rows", "table-head-tabs", "parent-selected-tab", "current-page", "per-page", "onChangeTab", "onChangeViewConfiguration", "onSort", "onOpenForm", "onRemoveItem"])
      ]),
      _: 1
    }, 8, ["search-query"])
  ], 64))
}