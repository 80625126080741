
import { computed, defineComponent, ref } from "vue";
import { useLocations } from "@/hooks/locations/new/useLocations";
import { ITableHeadCell } from "@/components/Table/ts/interfaces/TableStructure";

import AppTableController from "@/components/Table/ts/AppTableController.vue";
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import ShadowControl from "@/components/Form/ShadowControl.vue";

import SelectDropdown from "@/components/ui/Dropdown/SelectDropdown.vue";
import AppToolbarTable from "@/components/Table/ts/AppToolbarTable.vue";
import DeleteLocationConfirm from "@/components/Locations/DeleteLocationConfirm.vue";
import CreateLocationModal from "@/components/Locations/CreateLocationModal.vue";
import { TPackageLocation } from "@/hooks/esim/location-packages/types/locationPackage.types";

export default defineComponent({
  name: "Locations",
  components: {
    CreateLocationModal,
    DeleteLocationConfirm,
    AppToolbarTable,
    SelectDropdown,
    ShadowControl,
    PageMainHeading,
    AppTableController
  },

  setup() {
    const location = useLocations({
      initialSearchQuery: ""
    });

    const parentSelectedTab = computed(
      () => location.tabs.selectedTab.value.title as TPackageLocation
    );

    const deleteModalOpened = ref(false);
    const deleteLocation = ref({ icon: "", name: "", id: -1 });

    function startLocationDelete(id: number) {
      const $location = location.locationsList.value
        .map(l => l)
        .find(location => location.id === id);

      if ($location) {
        deleteModalOpened.value = true;

        deleteLocation.value = {
          icon: $location.icon,
          name: $location.name,
          id
        };
      }
    }

    async function deleteConfirm(id: number) {
      await location.deleteLocation(id, parentSelectedTab.value);
      deleteModalOpened.value = false;
      deleteLocation.value = {
        icon: "",
        name: "",
        id: -1
      };
    }

    function deleteDiscard() {
      deleteModalOpened.value = false;
      deleteLocation.value = { icon: "", name: "", id: -1 };
    }

    return {
      ...location,
      startLocationDelete,
      deleteLocation,
      deleteModalOpened,
      viewConfiguration: location.viewConfiguration || { page: 1, perPage: 1 },
      parentSelectedTab,
      deleteConfirm,
      deleteDiscard,
      pageTitle: computed(() => {
        return location.tabs.selectedTab.value.title;
      })
    };
  },

  computed: {
    tableHead(): ITableHeadCell[] {
      const { byName, byCreated } = this.currentSort || {};

      return [
        {
          size: "fluid",
          label: "Title",
          id: "location-title",
          sort: {
            keyName: byName.keyName,
            order: byName.order
          }
        },

        {
          size: "lg",
          label: "Date created",
          id: "data-created",
          rightAlign: true,
          sort: {
            keyName: byCreated.keyName,
            order: byCreated.order
          }
        },

        {
          size: "md",
          label: "Status",
          id: "location-status",
          rightAlign: true
        },

        {
          size: "smL",
          label: "Actions",
          id: "location-actions",
          rightAlign: true
        }
      ];
    }
  }
});
